<div class="modal">
  <div class="modal-content">
    <div class="bio-modal-header">
      <span class="bio-modal-title">{{ title }}</span>
      <span class="close" (click)="modalCloseClicked()">&times;</span>
    </div>
    <div class="bio-modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="bio-modal-footer"></div>
  </div>
</div>
