export const environment = {
  production: true,
  cognito: {
    userPoolId: 'eu-west-2_p9A9v4RnL',
    userPoolWebClientId: '4i5o4etm4v9ptm5usohncbq5hn',
  },
  fileSearchBaseUrl:
    'https://s2hetkhk88.execute-api.eu-west-2.amazonaws.com/v2',
  quickSightApiEndPoint:
    'https://8rzyxj4ej1.execute-api.eu-west-2.amazonaws.com/v1',
  surveyUploadBucket: 'odl-dev-raw-bucket',
  gisdataUrl: 'https://biodiversity-gis-data.s3.eu-west-2.amazonaws.com',
  portalConfiguration: {
    surveyThemes: {
      name: 'Dev_SurveyThemes',
      path: 'survey-themes',
    },
    projectPhases: {
      name: 'Dev_ProjectPhases',
      path: 'project-phases',
    },
    windfarms: {
      name: 'Dev_CountryWindFarm',
      path: 'windfarms',
    },
    categories: {
      name: 'Dev_Categories',
      path: 'categories',
    },
    confidencepolicies: {
      name: 'Dev_Confidentiality',
      path: 'confidencepolicies',
    },
    usecase: {
      name: 'Dev_UseCase',
      path: 'usecases',
    },
  },
};
