<section class="main">
  <section class="header">
    <app-header
      [isMobile]="isMobile"
      *ngIf="
        selectedRoute !== '/auth/sign-in' && selectedRoute !== '/auth/sign-up'
      "
    ></app-header>
  </section>
  <section
    class="main_content"
    [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
  </section>
</section>
