import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/datamanagement', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'datamanagement',
    loadChildren: () =>
      import('./modules/data-management/data-management.module').then(
        (m) => m.DataManagementModule
      ),
  },
  {
    path: 'gisviewer',
    loadChildren: () =>
      import('./modules/gis-viewer/gis-viewer.module').then(
        (m) => m.GisViewerModule
      ),
  },
  {
    path: 'analyticsview',
    loadChildren: () =>
      import('./modules/analytics-view/analytics-view.module').then(
        (m) => m.AnalyticsViewModule
      ),
  },
  { path: '**', redirectTo: '/auth/sign-in' }, // Redirect wildcard route to sign-in in AuthModule
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
