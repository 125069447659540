import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() isMobile: boolean = false;
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  initials: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cdRef: ChangeDetectorRef
  ) {}

  firstName: string = '';
  lastName: string = '';
  email: string = '';
  cognitoUserId: string = '';

  async ngOnInit(): Promise<void> {
    this.getUserDetails();
  }

  toggleSidebar() {
    this.toggleSidebarForMe.emit();
  }

  signOutWithCognito() {
    this.authenticationService.signOut().then(() => {
      this.router.navigate(['/sign-in']);
    });
  }

  private getUserDetails() {
    this.authenticationService.getUser().then((user: any) => {
      if (user) {
        // Extract user details and populate the properties
        this.firstName = user.attributes.given_name;
        this.lastName = user.attributes.family_name;
        this.email = user.attributes.email;
        this.cognitoUserId = user.attributes.sub;
        this.initials =
          this.firstName.substring(0, 1) + this.lastName.substring(0, 1);
        // Manually trigger change detection
        this.cdRef.detectChanges();
      } else {
        //if not logged in,send to sign in page
        this.router.navigate(['/sign-in']);
      }
    });
  }
}
