import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    });
  }

  /**
 * signUp
user: User */
  public signUp(user: User): Promise<any> {
    return Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        email: user.email,
        given_name: user.givenName,
        family_name: user.familyName,
      },
    });
  }

  public confirmSignUp(user: User): Promise<any> {
    return Auth.confirmSignUp(user.email, user.code);
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public async getIdentityToken(): Promise<string> {
    try {
      const res = await Auth.currentSession();
      const identityToken = res.getIdToken().getJwtToken();

      return identityToken;
    } catch (error) {
      console.error('Error fetching identity token:', error);
      throw error;
    }
  }

  public getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  public signIn(user: User): Promise<any> {
    return Auth.signIn(user.email, user.password);
  }

  public signOut(): Promise<any> {
    return Auth.signOut();
  }

  public forgotPassword(user: User): Promise<any> {
    return Auth.forgotPassword(user.email);
  }

  public forgotPasswordSubmit(user: User, newPassword: string): Promise<any> {
    return Auth.forgotPasswordSubmit(user.email, user.code, newPassword);
  }
}
