<mat-toolbar>
  <mat-toolbar-row class="justify-content-between">
    <div class="left-header">
      <div class="logo">
        <img src="./assets/logo.svg" alt="logo" />
        <h2 class="logo-title">Biodiversity<span></span></h2>
      </div>
    </div>
    <div class="center-header">
      <!-- Menu using Angular Material Menu - will moved to a new component -->
      <div class="nav2">
        <button
          mat-button
          class="nav-button"
          routerLinkActive="list-item-active"
          [matMenuTriggerFor]="filemanagement"
        >
          File Management
        </button>
        <button
          mat-button
          class="nav-button"
          routerLinkActive="list-item-active"
          routerLink="/gisviewer/gis-viewer"
        >
          GIS Viewer
        </button>
        <button
          mat-button
          class="nav-button"
          routerLinkActive="list-item-active"
          [matMenuTriggerFor]="dashboards"
        >
          Dashboards
        </button>
        <mat-menu #dashboards="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="northamerica">
            North America
          </button>
          <button mat-menu-item>United Kingdom</button>
        </mat-menu>
        <mat-menu #filemanagement="matMenu">
          <button mat-menu-item routerLink="/datamanagement/file-upload">
            File Upload
          </button>
          <button mat-menu-item routerLink="/datamanagement/file-search">
            File Search
          </button>
        </mat-menu>
        <mat-menu #northamerica="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="southforkwind">
            South Fork Wind
          </button>
        </mat-menu>

        <mat-menu #southforkwind="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="ventlesstrapsurvey">
            Ventless Trap Survey
          </button>
        </mat-menu>

        <mat-menu #ventlesstrapsurvey="matMenu">
          <button mat-menu-item routerLink="/analyticsview/dashboard">
            Data
          </button>
          <button mat-menu-item>Analytical</button>
        </mat-menu>
      </div>
    </div>

    <div class="right-header">
      <ul class="m-0 align-items-center">
        <li>
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            class="user d-flex align-items-center"
          >
            <!-- <img src="./assets/user.jpg" alt="" class="user-image mr-1 p-2" /> -->
            <div id="avatar" class="avatar">{{ initials }}</div>
            {{ firstName }}
            <i class="bx bxs-chevron-down user-image-icon"></i>
            <!-- <mat-icon class="user-image-icon ml-1"
              >keyboard_arrow_down</mat-icon
            > -->
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="signOutWithCognito()">
              <mat-icon>exit_to_app</mat-icon>
              Logout
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
