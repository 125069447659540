import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MessageModalComponent, HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [MessageModalComponent, HeaderComponent],
})
export class SharedModule {}
