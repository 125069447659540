import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: Object | undefined;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/appconfig/appConfig.json')
      .toPromise()
      .then((data) => {
        this.appConfig = data;
      });
  }

  getConfig() {
    return this.appConfig;
  }
}
